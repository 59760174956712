interface Name {
  first: string
  middle: string | null
  last: string
  firstLast: string
}

interface NameWithInitials extends Name {
  initials: string
}

const getName = (fullName: string): NameWithInitials => {
  let first = ""
  let second = ""
  let third = ""

  let nameNumber = 0

  const charArray = fullName.split("")
  for (const char of charArray) {
    if (char === " ") {
      nameNumber += 1
      continue
    }

    if (nameNumber === 0) {
      first += char
    } else if (nameNumber === 1) {
      second += char
    } else if (nameNumber === 0) {
      third += char
    }
  }

  let n = {
    first,
    middle: third === "" ? null : second,
    last: third === "" ? second : third,
  }

  const name: Name = {
    ...n,
    firstLast: n.first + " " + n.last,
  }

  return {
    ...name,
    initials: getInitials(name),
  }
}

const getInitials = (name: Name): string => {
  let initials = ""
  if (name.first.length > 0) initials += name.first[0]
  if (name.middle) {
    if (name.middle.length > 0) initials += name.middle[0]
  }
  if (name.last.length > 0) initials += name.last[0]

  return initials
}

export default getName
